import React, { useState } from "react";
import { Home, Person, Badge } from "@mui/icons-material";

import { routePaths } from "../constants/routes";
import SEO from "../components/common/seo";
import Header from "../components/common/Header";
import { SubContainer } from "../components/common/Styled";
import Loader from "../components/common/Loader";
import IdentityForm from "../components/IdentityForm";

const IdentityPage = () => {
  const title = "Mes informations personelles";

  const items = [
    {
      icon: <Home />,
      label: "Accueil",
      route: routePaths.home,
    },
    {
      icon: <Person />,
      label: "Mon compte",
      route: routePaths.profile,
    },
    {
      icon: <Badge />,
      label: title,
    },
  ];

  return (
    <>
      <SEO title="Informations personelles" />
      <SubContainer>
        <Header {...{ items, title }} />
        <IdentityForm />
      </SubContainer>
    </>
  );
};

export default IdentityPage;
