import React from 'react'
import { navigate } from 'gatsby'
import { Button } from 'gatsby-theme-material-ui'
import { Form, Field } from 'react-final-form'
import { isEqual, isEmpty } from 'lodash'

import { routePaths } from '../../constants/routes'
import { useAppContext } from '../../contexts/appContext'
import { useProfileContext } from '../../contexts/profileContext'
import {
  FieldContainer,
  FormContainer,
  StyledTextField
} from '../common/Styled'

const IdentityForm = () => {
  const profile = useProfileContext()
  const showSnackbar = useAppContext()?.showSnackbar
  const user = profile?.user
  const updateUser = profile?.updateUser

  if (!user) return null

  const initialValue = {
    firstName: user.firstName,
    lastName: user.lastName,
    email: user.email
  }

  const handleSubmit = async values => {
    const { firstName, lastName, email } = values
    const data = {
      firstName,
      lastName,
      email
    }
    try {
      await updateUser(data)
      showSnackbar('success', 'Données mise à jour.')
      navigate(routePaths.profile)
    } catch (e) {
      showSnackbar('error', 'Un problème est survenu, veuillez réessayer.')
    }
  }

  return (
    <Form
      onSubmit={handleSubmit}
      initialValues={initialValue}
      validate={values => {
        const errors: any = {}
        if (!values.firstName) errors.firstName = 'Required'
        if (!values.lastName) errors.lastName = 'Required'
        if (!values.email) errors.email = 'Required'
        return errors
      }}
      subscription={{ pristine: true, submitting: true }}
      render={({ handleSubmit, errors, pristine, submitting }) => (
        <FormContainer onSubmit={handleSubmit}>
          <FieldContainer>
            <Field name="firstName">
              {({ input, meta }) => (
                <StyledTextField
                  {...input}
                  error={meta.touched && meta.error}
                  label="Prénom"
                  variant="outlined"
                  type="text"
                  required
                />
              )}
            </Field>
            <Field name="lastName">
              {({ input, meta }) => (
                <StyledTextField
                  {...input}
                  error={meta.touched && meta.error}
                  label="Nom"
                  variant="outlined"
                  type="text"
                  required
                />
              )}
            </Field>
            <Field name="email">
              {({ input, meta }) => (
                <StyledTextField
                  {...input}
                  error={meta.touched && meta.error}
                  label="Email"
                  variant="outlined"
                  required
                  type="text"
                  inputMode="email"
                />
              )}
            </Field>
          </FieldContainer>
          <Button
            disabled={pristine || !isEmpty(errors) || submitting}
            variant="contained"
            color="primary"
            type="submit"
          >
            Enregistrer
          </Button>
        </FormContainer>
      )}
    />
  )
}

export default IdentityForm
